.element-list-wrapper {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.element-list-header {
    position: fixed;
    height: 60px;
    background-color: rgb(255, 255, 255);
    width: 420px;
    z-index: 99;
    padding-top: 20px;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;