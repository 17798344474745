.nothing-selected-wrapper {
    position: absolute;
    top: 25vh;
    transform: translate(-50%, -50%);
}

.nothing-selected-icon {
    font-size: 50px;
    color: rgba(0, 0, 0, .45) !important;
    margin-bottom: 10px;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;