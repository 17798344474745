.col-wrapper {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    padding-right: -10px;
}

.parent-checkbox-label {
    font-weight: bold;
    font-size: 18px;
}

.parent-checkbox-label-disabled {
    font-weight: bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.2);
}

.child-checkbox-label {
    font-weight: 600;
    font-size: 16px;
}

.child-checkbox-label-disabled {
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.2);
}

.display-card {
    transition: opacity 1s ease-out;
    opacity: 1;
}

.visible-card {
    display: none;
}

.large-card-container {
    border-radius: 9px;
}

.small-card-container {
    -webkit-box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    -moz-box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    border-radius: 7px;
}

.checkbox-group {
    margin-top: 5px;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;