.project-list-wrapper{
    height: 90vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: "#fffff" !important;
}

.project-list-header {
    position: fixed;
    height: 150px;
    background-color: #ffffff;
    width: 320px;
    padding-top: 16px;
    z-index: 99;
}

.project-list{
    margin-top: 10px;
}

// .list{
//     // background-color: #F5F5F5;
//     // padding: 5px;
//     // &:hover{
//     //     background-color: #F5F5F5;
//     // }
// }
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;