.role-parent {
    font-size: 18px;
    font-weight: 800;
}
.role-child {
    font-size: 17px;
    font-weight: 700;
}
.role-sub-child {
    font-size: 16px;
    font-weight: 600;
}
.timeline-header {
    margin-bottom: 20px !important;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;