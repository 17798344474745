// @import '~antd/dist/antd.dark.less';
.login-wrapper{
    // background: rgba(0,0,0,.9);
    height: 100vh;
    background-image: url("../../assets/images//login-bg-01.svg");
    background-repeat: no-repeat;
    // background-size: cover;
    background-size: cover;
}
.login-bg{
    background-image: url("../../assets/images//login-bg-01.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.login-logo{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 18%;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;