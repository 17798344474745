.header {
    color: #fff;
    font-size: 30px;
    padding: 0;
    font-weight: 500;
}

.user-info {
    font-size: 14px;
    border: none;
    background-color: white;
    float: right;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: right;
    margin-top: 12px;
    object-fit: cover;
}

.user-avatar-datatable {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    // float: right;
    margin-right: 20px;
    margin-left: 20px;
    object-fit: cover;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;