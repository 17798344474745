@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@import '~antd/dist/antd.less';
// @body-background: #FAFAFA; 
// @layout-header-background:#FAFAFA;
// // @import '~antd/dist/antd.dark.less';

// #components-layout-demo-side .logo {
//   height: 32px;
//   margin: 16px;
//   background: rgba(255, 255, 255, 0.3);
// }

// .site-layout .site-layout-background {
//   background: #fff;
// }

.ant-layout-header {
    background: #fff;
}

#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(0, 0, 0);
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/BMW_logo_%28gray%29.svg/2048px-BMW_logo_%28gray%29.svg.png');
}

.logo {
    float: left;
    width: 400px;
    height: 55px;
    margin: 0 auto;
    line-height: 55px;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
    background: rgb(0, 0, 0);
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/BMW_logo_%28gray%29.svg/2048px-BMW_logo_%28gray%29.svg.png');
}

.site-layout-background {
    background: #fff;
}

.project-list {
    padding: 15px;
    border-left: 5px solid #ffffff;
}

.active-project-list {
    padding: 15px;
    border-left: 5px solid #0052F6;
    background-image: linear-gradient(to right, rgba(225, 225, 225, 0.434), rgba(0, 0, 0, 0));
}

.ant-layout-sider {
    background: rgb(180, 180, 180);
}

.ant-layout-sider-trigger {
    bottom: 16px;
}

// collapse
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 24px;
    overflow: hidden;
    background: #f7f7f7;
    border: 0px;
    border-radius: 2px;
}

.card-container {
    -webkit-box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    -moz-box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    border-radius: 7px;
    margin-bottom: 20px;
}

.card-label {
    font-size: 17px;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;