.project-list{
    position: relative;
}
.project-list:hover{
    cursor: pointer;
    color: lightskyblue;
    .more-option-wrapper{
        display: block;
    }
}
.more-option-wrapper{
    position: absolute;
    top: 50%;
    right: 5%;
    display: none;
}
.nothing-selected-wrapper{
    position: absolute;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;