.card-container {
    -webkit-box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    -moz-box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    box-shadow: 0px 11px 17px 3px rgba(235, 235, 235, 1);
    border-radius: 7px;
    margin-bottom: 20px;
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-xl {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.user-info-label {
  font-size: 17px;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;