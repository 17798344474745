.footer-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 24px;
    line-height: 24px;
    padding-left: 24px;
    background-color: #f0f2f5;
    font-size: 14px;
    z-index: 99;
}

.online-status {
    color: #0052F6;
}

.offline-status {
    color: #FF242C;
}
@primary-color: #0052F6;@error-color: #FF242C;@border-radius-base: 4px;